import React, { useState } from "react";
import "./talkStyle.css";
import earth from "../Assets/earth.png";
import hat from "../Assets/hat.png";
import WaitlistModal from "./Waitlist";

export const TalkFrame = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="frame">
      <div className="CTA">
        <div className="group">
          <div className="overlap-group">
            <img className="earth-image" alt="Earth" src={earth} />
            <img className="hat-image" alt="Hat" src={hat} />
          </div>
        </div>
        <div className="text-container">
          <div className="just-talk">JUST TALK...</div>
          <p className="and-sarge-AI-will">
            And Sarge AI will guide you
            <br />
            step-by-step to get your sh*t done.
          </p>
        </div>
        <div className="button" onClick={toggleModal}>
          <div className="text-wrapper">Join the waitlist</div>
          <div className="arrow-right">→</div>
        </div>
      </div>
      <WaitlistModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default TalkFrame;
