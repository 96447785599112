import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <div className="privacy-content">
        <p>This Privacy Policy explains how SargeAI collects, uses, and protects your information when you use our browser extension. We are committed to protecting your privacy and handling your data in an open and transparent manner.</p>
        
        <h2>Information We Collect</h2>
        <p>SargeAI captures screenshots of your browser activity approximately every couple of seconds. These screenshots are necessary for the core functionality of our service, which uses AI to guide users in completing tasks. We also collect email addresses from users who choose to subscribe to our updates, newsletters, and promotional offers.</p>
        
        <h2>How We Use Your Information</h2>
        <p>While SargeAI can see your browsing data through the screenshots, we do not store or collect this data permanently. Screenshots are sent to our servers for processing but are not retained or stored. They are used solely for the purpose of providing real-time AI-guided assistance. We use email addresses to send updates about SargeAI, deliver occasional newsletters, and share promotional offers.</p>
        
        <h2>Third-Party Services</h2>
        <p>We use OpenAI's API to power our AI functionality. As such, OpenAI may have access to the data processed through their API, including the content of screenshots. For more information on how OpenAI handles data, please refer to their privacy policy. We do not sell, rent, or share your personal information with other third parties, except as required by law or to provide our service.</p>
        
        <h2>Data Security</h2>
        <p>We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
        
        <h2>Your Rights</h2>
        <p>You have the right to opt out of email subscriptions and newsletters at any time, request information about the personal data we hold about you, and request correction or deletion of your personal data, where applicable. To exercise these rights, please contact us at <a href="mailto:adam@banterai.app">adam@banterai.app</a>.</p>
        
        <h2>Changes to This Policy</h2>
        <p>We encourage you to review this policy periodically for any changes. Your continued use of SargeAI after any changes to this policy indicates your acceptance of the updated terms. Contact Us If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at adam@banterai.app. </p>
        
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:adam@banterai.app">adam@banterai.app</a>.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;