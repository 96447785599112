import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ADHDAssistant from './components/ADHDAssistant.jsx';
import Einstein from './components/Einstein.jsx';
import TransitionFrame from './components/transition.jsx';
import VideoFrame from './components/video.jsx';
import FuckFrame from './components/fuck.jsx';
import TalkFrame from './components/talk.jsx';
import NavigationBar from './components/NavigationBar.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <Router>
      <div className="App">
        <NavigationBar />
        <Routes>
          <Route path="/" element={
            <>
              <ADHDAssistant />
              <Einstein />
              <TransitionFrame />
              <VideoFrame />
              <Analytics />
              <FuckFrame />
              <TalkFrame />
            </>
          } />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <footer className="footer">
          <p>
            <Link to="/privacy" className="footer-link">Privacy Policy</Link>
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;