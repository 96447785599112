import React, { useState } from 'react';
import '../NavigationBar.css';
import logoIcon from '../Assets/Banter AI Logo.png'; // Replace with the path to your image file
import WaitlistModal from './Waitlist'; // Ensure this import path is correct

function NavigationBar() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar__logo">
          <img src={logoIcon} alt="SARGE AI Logo" />
        </div>
        <button className="navbar__join-button" onClick={toggleModal}>
          <div className="navbar__text-wrapper">Join waitlist</div>
        </button>
      </nav>
      <WaitlistModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}

export default NavigationBar;
