import React, { useState, useEffect, useRef } from "react";
import { createClient } from "@supabase/supabase-js";

// Initialize Supabase client
const supabaseUrl = "https://jhjejgjgufscfvcpfwle.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpoamVqZ2pndWZzY2Z2Y3Bmd2xlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI0OTM2ODgsImV4cCI6MjAzODA2OTY4OH0.kG44YsfevOHt5a8tvjtHjma5tJFPzGPv2uQswyzHs9E";
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default function WaitlistModal({ isOpen, onClose }) {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef.current && !isRegistered) {
      inputRef.current.focus();
    }
  }, [isOpen, isRegistered]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const addUserToWaitlist = async (email) => {
    if (!validateEmail(email)) {
      setError("Invalid email address.");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const { data, error } = await supabase
        .from("waitlist")
        .insert([{ email: email }]);

      if (error) {
        if (error.code === "23505") {
          // Unique constraint error code
          setIsRegistered(true);
        } else {
          throw error;
        }
      } else {
        setIsRegistered(true);
      }
      setEmail("");
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      setError("There was an error. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isRegistered) {
      addUserToWaitlist(email);
    }
  };

  if (!isOpen) return null;

  return (
    <div style={styles.modal}>
      <div style={styles.content}>
        <button onClick={onClose} style={styles.closeButton}>
          ×
        </button>
        <h2 style={styles.title}>Join Our Waitlist</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            ref={inputRef}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={styles.input}
            required
            disabled={isRegistered}
          />
          <button
            type="submit"
            style={{
              ...styles.button,
              ...(isRegistered ? styles.buttonSuccess : {}),
              ...(isSubmitting ? styles.buttonSubmitting : {}),
            }}
            disabled={isSubmitting || isRegistered}
          >
            {isRegistered 
              ? "Successfully Registered!" 
              : isSubmitting 
                ? "Joining..." 
                : "Join Waitlist"}
          </button>
        </form>
        {error && <p style={styles.errorMessage}>{error}</p>}
      </div>
    </div>
  );
}

const styles = {
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  content: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "32px",
    width: "90%",
    maxWidth: "400px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "16px",
    right: "16px",
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    color: "#999",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "24px",
    textAlign: "center",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    width: "100%",
    padding: "12px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    fontSize: "16px",
    marginBottom: "16px",
    outline: "none",
    transition: "border-color 0.3s",
  },
  button: {
    width: "100%",
    padding: "12px",
    backgroundColor: "#4a90e2",
    color: "white",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  buttonSuccess: {
    backgroundColor: "#4CAF50",
    cursor: "default",
  },
  buttonSubmitting: {
    backgroundColor: "#ccc",
    cursor: "not-allowed",
  },
  errorMessage: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
    border: "1px solid #f5c6cb",
    borderRadius: "4px",
    padding: "10px",
    marginTop: "10px",
    textAlign: "center",
    fontSize: "14px",
  },
};