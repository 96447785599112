import React, { useState } from "react";
import "./ADHDAssistant.css"; // Make sure this path is correct for your project structure
import WaitlistModal from "./Waitlist";

function ADHDAssistant(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="adhd-assistant__main-container">
      <header className="adhd-assistant__header">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
          integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
          crossOrigin="anonymous"
        />
        <span className="adhd-assistant__text--white">free your </span>
        <span className="adhd-assistant__text--red">adhd</span>
      </header>
      <section className="adhd-assistant__description">
        Meet Sarge AI, the first-ever AI assistant designed to work with your
        ADHD, not against it.
      </section>
      <div className="adhd-assistant__waitlist-button" onClick={toggleModal}>
        <span className="adhd-assistant__join-button">Join the waitlist</span>
        {/* <i className="adhd-assistant__arrow-icon fas fa-arrow-right"></i> */}
      </div>
      <WaitlistModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default ADHDAssistant;