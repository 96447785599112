import React from "react";
import "./fuckStyle.css";
import noSymbol from "../Assets/no-symbol.png"; // Ensure you have this image in the correct path

export const FuckFrame = () => {
  return (
    <div className="frame">
      <div className="phrase">
        <img src={noSymbol} alt="No symbol" className="no-symbol" />
        <span className="emphasized"> willpower.</span>
      </div>
      <div className="phrase">
        <img src={noSymbol} alt="No symbol" className="no-symbol" />
        <span className="emphasized"> being organized.</span>
      </div>
      <div className="phrase">
        <img src={noSymbol} alt="No symbol" className="no-symbol" />
        <span className="emphasized"> learning new tools.</span>
      </div>
    </div>
  );
};

export default FuckFrame;