import React from "react";
import "./videoStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHatCowboy } from "@fortawesome/free-solid-svg-icons";

export const VideoFrame = () => {
  return (
    <div className="video-frame">
      <div className="video-frame__div">
        <div className="video-frame__introducing">INTRODUCING...</div>
        <div className="video-frame__logo">
          <div className="video-frame__hat-cowboy-wrapper">
            <FontAwesomeIcon className="video-frame__hat-cowboy" icon={faHatCowboy} />
          </div>
          <div className="video-frame__sarge-AI">SARGE AI</div>
        </div>
        <div className="video-frame__div">
          <div className="video-frame__div-2">

            <iframe
              className="video-frame__video-embed"
              src="https://www.youtube.com/embed/w8fMdDdGmyg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
            ></iframe>
            {/* <div className="video-frame__group">
              <div className="video-frame__overlap-group">
                <img
                  className="video-frame__cleanshot"
                  alt="Cleanshot"
                  src="https://c.animaapp.com/ZSNe1o2y/img/cleanshot-2023-10-25-at-00-35-1@2x.png"
                />
                <img
                  className="video-frame__img"
                  alt="Cleanshot"
                  src="https://c.animaapp.com/ZSNe1o2y/img/cleanshot-2023-10-25-at-00-36-1@2x.png"
                />
              </div>
            </div> */}
            {/* <div className="video-frame__div-3">
              <div className="video-frame__text-wrapper">See how it works</div>
              <div className="video-frame__ellipse" />
              <div className="video-frame__ellipse-2" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoFrame;

