// import React from "react";

// function Transition(props) {
//   return (
//     <div className="transition__main-container">
//       <header className="transition__header">Luckily, you no longer have to.</header>
//       <style jsx>{`
//         .transition__main-container {
//           color: #fff; // Assuming you want the color to be white
//           letter-spacing: -0.12px;
//           background: linear-gradient(180deg, #000 0%, #fff 100%);
//           width: 100%;
//           justify-content: center;
//           padding: 64px 20px 324px;
//           font: 400 24px/31px 'Test Untitled Serif', -apple-system, Roboto, Helvetica, sans-serif;
//         }

//         .transition__header {
//           color: #fff; // Assuming you want the color to be white
//           text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Text shadow for better visibility
//         }

//         @media (max-width: 991px) {
//           .transition__main-container {
//             padding-bottom: 40px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// }

// export default Transition;
import React from "react";
import "../transitionStyle.css";

export const TransitionFrame = () => {
  return (
    <div className="transition-frame">
      <p className="transition-text-wrapper">Luckily, you no longer have to.</p>
    </div>
  );
};

export default TransitionFrame;

