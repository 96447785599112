import * as React from "react";
import boyImage from "../Assets/boy.png";
import einstein from "../Assets/einstein frame.png";
import lock from "../Assets/lock.png";
import teen from "../Assets/teen.png";
import teentwo from "../Assets/teen2.png";

function Einstein(props) {
  return (
    <>
      <div className="box">
        <div className="div">We get it...</div>
        <div className="div-2">Working with ADHD is chaos.</div>
        <img
          loading="lazy"
          //   srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4623cfe671a4fc7071311bab47063027e00cedf0c2c885f855eadafa6f946a33?apiKey=44707d063c744921bc9227989c9b8ef0&"
          className="img"
          src={boyImage}
        />
        <div className="div-3">but guess what?</div>
        <div className="div-4">Einstein had ADHD, too.</div>
        <img
          loading="lazy"
          //   srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/73767de03098eeba0230eb7316e33573a2339405d21614d3e711bda1693f9218?apiKey=44707d063c744921bc9227989c9b8ef0&"
          //   className="img-2"
          className="img"
          src={einstein}
        />
        <div className="div-5">His secret?</div>
        <div className="div-6">He didn’t suppress his ADHD with Adderall.</div>
        <img
          loading="lazy"
          //   srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7b0a8ffdc68da07764782992862a01da30b776794f7c1710a1f412e2ac8acd0?apiKey=44707d063c744921bc9227989c9b8ef0&"
          //   className="img-3"
          className="img"
          src={lock}
        />
        <div className="div-7">He made it his SUPERPOWER!</div>
        <div className="div-8">The truth is...</div>
        <div className="div-9">
          You might be lazy, but you’re brilliant, too.
          <br />
          <br />
          It’s the stupid stuff that holds you back.
        </div>
        <img
          loading="lazy"
          //   srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c866f20968cdfb91d7027561ecf7d34c8e26200ebd7051529a86d49a530d956?apiKey=44707d063c744921bc9227989c9b8ef0&"
          //   className="img-4"
          className="img"
          src={teen}
        />
        <div className="div-10">if only...</div>
        <div className="div-11">
          You didn’t have to figure out what to work on next, pull up materials,
          or context switch.
          <br />
          <br />
          You’d be free to think. To create. To succeed.
        </div>
        <img
          loading="lazy"
          //   srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ec28700864a48629a2334e294859aadf5642aacecf689150d04e9410a3741?apiKey=44707d063c744921bc9227989c9b8ef0&"
          //   className="img-5"
          className="img"
          src={teentwo}
        />
      </div>
      <style jsx>{`
        .box {
          align-items: center;
          background-color: #000;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 50px 0px;
        }
        .div {
          color: #ec1d25;
          text-align: center;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          letter-spacing: 0.8px;
          text-transform: uppercase;
          align-self: center;
          margin-top: 30px;
          white-space: nowrap;
          font: 700 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div {
            white-space: initial;
          }
        }
        .div-2 {
          align-self: center;
          color: var(--White, #fff);
          letter-spacing: -0.12px;
          margin-top: 16px;
          font: 400 24px/31px Test Untitled Serif, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: auto;
          overflow: hidden;
          align-self: stretch;
          max-width: 500px; /* Maximum width of the image */
          margin: 40px auto 80px; /* Center the image and set vertical margins */
        }
        .img {
            object-fit: fill; /* This will stretch the image to completely fill the container */
            width: 100%; /* Ensures the image stretches across the width */
            height: 100%; /* Ensures the image stretches across the height */
            overflow: hidden;
            align-self: stretch;
            margin-bottom: 80px;
          }
        @media (max-width: 991px) {
          .img {
            margin-top: 40px;
          }
        }
        .div-3 {
          color: #ec1d25;
          text-align: center;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          letter-spacing: 0.8px;
          text-transform: uppercase;
          align-self: center;
          margin-top: 80px;
          white-space: nowrap;
          font: 700 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div-3 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-4 {
          align-self: center;
          color: var(--White, #fff);
          letter-spacing: -0.12px;
          margin-top: 16px;
          font: 400 24px/31px Test Untitled Serif, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .img-2 {
          aspect-ratio: 1.11;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 80px;
        }
        @media (max-width: 991px) {
          .img {
            width: 100%; /* Full width on smaller screens */
            margin-top: 40px; /* Adjust margin-top as per your design */
        }
        }
        .div-5 {
          color: #ec1d25;
          text-align: center;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          letter-spacing: 0.8px;
          text-transform: uppercase;
          align-self: center;
          margin-top: 80px;
          white-space: nowrap;
          font: 700 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div-5 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-6 {
          align-self: center;
          color: var(--White, #fff);
          letter-spacing: -0.12px;
          margin-top: 16px;
          font: 400 24px/31px Test Untitled Serif, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .img-3 {
          aspect-ratio: 1.07;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 80px;
        }
        @media (max-width: 991px) {
          .img-3 {
            margin-top: 40px;
          }
        }
        .div-7 {
          align-self: center;
          color: var(--White, #fff);
          letter-spacing: -0.12px;
          margin-top: 80px;
          font: 400 24px/31px Test Untitled Serif, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .div-7 {
            margin-top: 40px;
          }
        }
        .div-8 {
          color: #ec1d25;
          text-align: center;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          letter-spacing: 0.8px;
          text-transform: uppercase;
          align-self: center;
          margin-top: 80px;
          white-space: nowrap;
          font: 700 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div-8 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-9 {
          align-self: center;
          color: var(--White, #fff);
          letter-spacing: -0.12px;
          margin-top: 16px;
          font: 400 24px/31px Test Untitled Serif, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .img-4 {
          aspect-ratio: 1.12;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 80px;
        }
        @media (max-width: 991px) {
          .img-4 {
            margin-top: 40px;
          }
        }
        .div-10 {
          color: #ec1d25;
          text-align: center;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          letter-spacing: 0.8px;
          text-transform: uppercase;
          align-self: center;
          margin-top: 80px;
          white-space: nowrap;
          font: 700 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div-10 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-11 {
          align-self: center;
          color: var(--White, #fff);
          letter-spacing: -0.12px;
          margin-top: 16px;
          margin-bottom: 20px;
          font: 400 24px/31px Test Untitled Serif, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .img-5 {
          aspect-ratio: 1.23;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin: 80px 0 30px;
        }
        @media (max-width: 991px) {
          .img-5 {
            margin-top: 40px;
          }
        }
      `}</style>
    </>
  );
}

export default Einstein;
